.ContentUploadContainer{
          width: 94%;
          background-color: white;
          margin: auto;
          margin-top: 20px;
          border-radius: 10px;
}

.contentWritingpart{
          width: 29pc;
          margin-left: 10px;
          margin-top: 5px;
          height: 4vh;
          border: none;
}

.profileimage{
          width:40px;
          height: 40px;
          border-radius: 50%;
          margin-left: 10px;
          
}

.icons{
          width: 24px;
          height: 24px;
          margin-left: 20px;
          margin-top: 49px;
}
.PostIcons{
          width: 24px;
          height: 24px;
}