.ProfileContainer{
          background-color: rgb(235, 238, 242);

}

.subProfileContainer{
          width: 80%;
          margin: auto;
          display: flex;
          justify-content: space-between;
}