.ProfilemainPostContainer{
          flex: 3;
          
}

.profileCoverimage{
          width: 92%;
          border-radius: 10px;
          height: 20vh;
          object-fit: cover;
          margin-top: 20px;
}