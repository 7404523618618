.Leftbar{
          flex: 1;
}
.NotificationsContainer{
          width: 18pc;
          height: 60vh;
          background-color: white;
          margin-top: 20px;
          border-radius: 20px;
          overflow: hidden;
          overflow-y: scroll;
}

.notificationimg{
          width:37px;
          height: 37px;
          border-radius: 50%;
          margin-left: 10px;
}
.followinguserimage{
          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-left: 40px;

}

.likeimage{
          width: 30px;
          height: 30px;
          margin-left: 43px;
          border-radius: 3px;
}

.exploreimage{
          width: 86px;
          margin-left: 4px;
          height: 150px;
          object-fit: cover;
          border-radius: 4px;
          margin-top: 6px;
}